import { setWindowResponsive } from "./domWindowResponsive.js";
import { addBrowserClass } from "./domBrowserDetect.js";


const RESPONSIVE_CLASSES = {
  '576': 'xs',
  '768': 'sm',
  '992': 'md',
  '1024': 'lg',
  '1200': 'xl',
  '1660': 'xxl'
}

const LARGE_CLASS = 'large';

export const domStart = () => {
  addBrowserClass();
  setWindowResponsive();
  addBodyResponsiveClass();

  let resizeTime;

  window.addEventListener('resize', e => {
    clearTimeout(resizeTime);
    resizeTime = setTimeout(() => {
      addBodyResponsiveClass();
    });
  });
  window.isXs = () => window.innerWidth < 576;
  window.isSm = () => window.innerWidth < 768;
  window.isMd = () => window.innerWidth < 992;
  window.isLg = () => window.innerWidth < 1024;
  window.isXl = () => window.innerWidth < 1200;
  window.isXxl = () => window.innerWidth < 1660;

  window.isLarge = () => window.innerWidth >= 1660;
}



const addBodyResponsiveClass = () => {
  removeClass(document.body, Object.values(RESPONSIVE_CLASSES).join(' ') + ' ' + LARGE_CLASS);
  let width = window.innerWidth;
  for (let key in RESPONSIVE_CLASSES) {
    if (width < parseFloat(key)) {
      addClass(document.body, RESPONSIVE_CLASSES[key]);
      return;
    }
  }
  addClass(document.body, LARGE_CLASS);
}



const addClass = (item, className) => {
  let allClassNames = getClassName(className);
  allClassNames.forEach(name => {
    if (!name) return;
    if (!item.classList.contains(name)) item.classList.add(name);
  });
}

const getClassName = (className) => {
  return className.split(" ").map(item => item.trim());
}


const removeClass = (el, className) => {
  let allClassNames = getClassName(className);
  allClassNames.forEach(name => {
    if (el.classList.contains(name)) el.classList.remove(name);
  });
}
